import { Cushion, FlexContainer, Rectangle, Typography } from '@pitchero/react-ui';
import React from 'react';
import PropTypes from 'prop-types';
import { montserrat } from 'lib/club-theme/typography';

const CategoryTag = ({ category }) => (
  <FlexContainer style={{ height: 'fit-content' }}>
    <Cushion horizontal="xsmall" vertical={2}>
      <Rectangle fill="primary" radius="rounded">
        <Typography
          color="primaryContrast"
          component="div"
          size="11px"
          family={montserrat}
          transform="uppercase"
          weight="bold"
        >
          {category}
        </Typography>
      </Rectangle>
    </Cushion>
  </FlexContainer>
);

CategoryTag.propTypes = {
  category: PropTypes.node.isRequired,
};

export default CategoryTag;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@pitchero/react-ui';

const InfoLine = ({ size, color, children }) => {
  return (
    <Typography component="div" preset="description" color={color} size={size}>
      {children}
    </Typography>
  );
};

InfoLine.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
};

InfoLine.defaultProps = {
  size: 13,
  color: 'dustygray',
};

export default InfoLine;
